export function useToc(cookieName: string) {
  const tocModel = useState(`toc_${cookieName}`, () => "0");

  const tocCookieSettings = {
    path: "/",
    expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000), // 1 year
  };

  const cookieRefreshToken = useCookie(cookieName, tocCookieSettings);

  if (cookieRefreshToken) {
    const strValue = String(cookieRefreshToken.value);
    const boolValue = Boolean(cookieRefreshToken.value);

    if (strValue === "0" || strValue === "1") {
      tocModel.value = strValue;
    } else {
      tocModel.value = boolValue ? "1" : "0";
    }
  }

  const isToc = computed((): boolean => tocModel.value !== "0");

  return {
    isToc,
    tocModel,
    tocCookieSettings,
  };
}
