<script setup lang="ts">
import { useToc } from "~/composables/toc";

const props = withDefaults(
  defineProps<{
    left?: string;
    leftIcon?: string;

    right?: string;
    rightIcon?: string;

    label?: string;
    toggleMaxWidth?: string;
    cookieName?: string;
  }>(),
  {
    left: "",
    leftIcon: "",

    right: "",
    rightIcon: "",

    label: "",
    toggleMaxWidth: "100%",
    cookieName: "",
  },
);

const model = defineModel<string>();
const { tocCookieSettings } = useToc(props.cookieName);

function updateCookie() {
  const { cookieName } = props;

  if (!cookieName) return;

  const cookieRefreshToken = useCookie(cookieName, tocCookieSettings);

  if (cookieRefreshToken.value === String(model.value)) return;

  cookieRefreshToken.value = String(model.value);
}

function toggle() {
  model.value = model.value === "0" ? "1" : "0";
}

const items = computed(() => {
  return [
    {
      label: props.left,
      icon: props.leftIcon,
      slot: "left",
    },
    {
      label: props.right,
      icon: props.rightIcon,
      slot: "right",
    },
  ];
});

watch(
  () => model.value,
  () => {
    updateCookie();
  },
);
</script>

<template>
  <div class="toggle-special">
    <div
      v-if="label"
      class="text-(--ui-text-muted) select-none pb-3 text-nowrap text-center"
      @click="toggle"
    >
      {{ label }}
    </div>

    <div v-else />

    <UTabs
      v-model="model"
      :items="items"
      :style="[`max-width: ${props.toggleMaxWidth}`]"
      color="neutral"
      :ui="{ list: 'border border-(--ui-border)' }"
    >
      <template #left>
        <slot name="grid" />
      </template>

      <template #right>
        <slot name="toc" />
      </template>
    </UTabs>
  </div>
</template>

<style scoped>
.toggle-special {
  max-width: v-bind(toggleMaxWidth);
}
</style>
